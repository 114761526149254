import React, { FC } from "react";
import { Link, graphql } from "gatsby";
import { VacancyQuery } from "../graphql-types";
import BackgroundImage from "gatsby-background-image";
import { FluidObject, FixedObject } from "gatsby-image";
import { Logo } from "../components/logo";
import { colors, fontStacks } from "../global-style";
import { Button } from "../components/button";
import { Center } from "../components/center";
import { Form } from "../components/form";
import Img from "gatsby-image";

export const query = graphql`
  query Vacancy($slug: String!) {
    vacancy(slug: { eq: $slug }) {
      vacancyId
      title
      content
      startDate
      region
    }

    heroBackground: file(
      relativePath: { eq: "baked-bakery-bread-205961_2.png" }
    ) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bread: file(relativePath: { eq: "bread.png" }) {
      childImageSharp {
        fixed(quality: 80, width: 500) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    cake: file(relativePath: { eq: "cake.png" }) {
      childImageSharp {
        fixed(quality: 80, width: 110) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    croissant: file(relativePath: { eq: "croissant.png" }) {
      childImageSharp {
        fixed(quality: 80, width: 170) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    slicedBread: file(relativePath: { eq: "sliced-bread.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const VacancyPage: FC<{
  data: VacancyQuery;
  location: { href: string };
}> = ({ data, location: { href } }) => {
  return (
    <>
      <BackgroundImage
        Tag="header"
        style={{
          minHeight: "30vh",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
        fluid={[
          "linear-gradient(rgba(20, 15, 0, 0.47), rgba(20, 15, 0, 0.47))",
          data.heroBackground.childImageSharp.fluid as FluidObject,
        ]}
      >
        <div
          style={{ marginTop: "0.5rem" }}
          css={`
            @media (max-width: 50rem) {
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 1rem;
              transform: scale(0.9);
            }
          `}
        >
          <Logo />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "3rem",
            textAlign: "center",
          }}
        >
          <Link to="/">
            <Button style={{ marginBottom: "1.5rem" }}>
              &larr; Alle vacatures
            </Button>
          </Link>

          <h1 style={{ maxWidth: "40ch", color: colors.white }}>
            {data.vacancy.title}
          </h1>
        </div>
      </BackgroundImage>

      <main
        style={{
          backgroundColor: colors.iceGrey,
          paddingBottom: "1rem",
        }}
      >
        <Center
          hasGutters={false}
          style={{
            position: "relative",
            top: "-1.5rem",
            boxShadow: "0px 5px 20px 0px rgba(0,0,0,0.05)",
          }}
        >
          <Img
            style={{
              position: "absolute",
              zIndex: 1,
              top: "20%",
              left: "-12rem",
            }}
            fixed={data.croissant.childImageSharp.fixed as FixedObject}
          />

          <Img
            style={{
              position: "absolute",
              zIndex: 1,
              top: "5%",
              right: "-9rem",
            }}
            fixed={data.cake.childImageSharp.fixed as FixedObject}
          />

          <Img
            style={{
              position: "absolute",
              zIndex: 1,
              top: "65%",
              right: "-30rem",
            }}
            fixed={data.bread.childImageSharp.fixed as FixedObject}
          />

          <section
            style={{
              backgroundColor: colors.white,
              paddingTop: "2rem",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              paddingBottom: "3rem",
            }}
          >
            <div style={{ overflow: "hidden" }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "-0.75rem",
                }}
              >
                <div
                  style={{
                    flexBasis: "40ch",
                    flexGrow: 999,
                    margin: "0.75rem",
                  }}
                >
                  <div
                    css={`
                      p {
                        margin-bottom: 1rem;
                      }

                      h3 {
                        font-family: ${fontStacks.barlow};
                        font-weight: 700;
                      }
                      
                      ul {
                        list-style-type: circle;
                        list-style-position: inside;
                      }
                    `}
                    dangerouslySetInnerHTML={{
                      __html: data.vacancy.content.replace(
                        /(?:\r\n|\r|\n)/g,
                        "<br />"
                      ),
                    }}
                  />
                </div>

                <div
                  style={{
                    flexBasis: "17rem",
                    flexGrow: 1,
                    margin: "0.75rem",
                  }}
                >
                  <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <Button
                      as="a"
                      href="#application"
                      style={{ display: "inline-block" }}
                    >
                      Solliciteer nu
                    </Button>
                  </div>

                  <div
                    style={{
                      border: `1px solid ${colors.lightGrey}`,
                      padding: "0.75rem",
                    }}
                    css={`
                     ul {
                        list-style-type: none;
                        list-style-position: inside;
                      }
                      `}

                  >
                    <h3>Details</h3>
                    <ul>
                      {data.vacancy.region && (
                        <li>Regio: {data.vacancy.region}</li>
                      )}

                      {data.vacancy.salary && (
                        <li>Salaris: {data.vacancy.salary}</li>
                      )}

                      <li>Vacaturenummer: {data.vacancy.vacancyId}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            style={{
              backgroundColor: colors.yellow,
              paddingTop: "3rem",
              paddingBottom: "3rem",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Img
              fluid={data.slicedBread.childImageSharp.fluid as FluidObject}
              style={{
                position: "absolute",
                right: 0,
                bottom: "0",
                width: "60%",
              }}
            />

            <div
              id="application"
              style={{
                position: "relative",
                maxWidth: "30rem",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "1.5rem",
                backgroundColor: colors.white,
                boxShadow: "0px 5px 30px 0px rgba(0,0,0,0.12)",
                paddingTop: "2rem",
                paddingBottom: "1rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              <Form
                header={
                  <div style={{ textAlign: "center" }}>
                    <h2>Solliciteer nu!</h2>
                    <p>Alle velden zijn verplicht</p>
                  </div>
                }
                vacancyId={data.vacancy.vacancyId}
              />
            </div>
          </section>
        </Center>
      </main>
    </>
  );
};

export default VacancyPage;
